import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import * as Config from './config';
import {useLazyQuery} from "@apollo/client";
import userQuery from '../gql/query/user/user.gql';
import Loading from "../components/Loading";

const UserContext = React.createContext({
    user: null,
    accessToken: null,
    role: null,
    setRole: (role) => {

    },
    logout: () => {
    },
});

function generateRandomString(length) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

async function generateCodeChallenge(codeVerifier) {
    const digest = await crypto.subtle.digest("SHA-256",
        new TextEncoder().encode(codeVerifier));

    return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const UserContextRef = React.createRef();

export function useUser() {
    const {user, logout, role, setRole} = useContext(UserContext);
    return [user, role, logout, setRole];
}

function useLogin() {
    const [timer, setTimer] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    /*
    if (!accessToken) {
        const url = new URL(window.location);
        if (url.searchParams.has('code')) {
            window.opener.postMessage({auth: {code: url.searchParams.get('code')}}, window.opener.location);
        }
    }
    */

    function refresh() {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }

        login(true).catch(e => console.log(e));
    }

    async function login(silent = false) {
        const codeVerifier = generateRandomString(64);
        const codeChallenge = await generateCodeChallenge(codeVerifier);

        const urlParams = new URLSearchParams({
            client_id: Config.get('oauthClientId'),
            response_type: 'code',
            redirect_uri: Config.get('oauthRedirectUri'),
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
        });
        const target = Config.get('oauthAuthUri') + '?' + urlParams.toString();
        const codePromise = fetch(target).then(response => {
            if (response.status === 200) {
                const url = new URL(response.url);
                if ((url.protocol + '//' + url.host + url.pathname) === Config.get('oauthRedirectUri') && url.searchParams.has('code')) {
                    return url.searchParams.get('code');
                } else {
                    console.log('oauthRedirectUri does not match or code does not exists')
                }
            }
            throw new Error('Not logged in');
        });

        return codePromise.then(code => {
            const data = new FormData();
            data.append('client_id', Config.get('oauthClientId'));
            data.append('grant_type', 'authorization_code');
            data.append('code', code);
            data.append('redirect_uri', Config.get('oauthRedirectUri'));
            data.append('code_verifier', codeVerifier);

            return fetch(Config.get('oauthTokenUri'), {
                body: data,
                method: 'POST',
            })
        }).then(response =>
            response.json()
        ).then(response => {
            setAccessToken(response.access_token);
            setTimer(setTimeout(refresh, parseInt(response.expires_in, 10) * 3 / 4 * 1000));
            return response;
        });
    }

    function logout() {
        fetch(Config.get('logoutUri')).then(() => {
            setAccessToken(null);
            window.location.reload(true);
        }).catch(e => console.log(e));
    }

    return [accessToken, logout, login];
}

export function UserProvider(props) {
    const [role, setRole] = useState(null);
    const [accessToken, logout, login] = useLogin();

    useImperativeHandle(UserContextRef, () => ({
        accessToken,
        logout,
    }));

    const [getUser, {loading, error, data}] = useLazyQuery(userQuery, {fetchPolicy: "cache-and-network"});

    if (error && error?.graphQLErrors.length) {
        logout();
    }

    useEffect(() => {
        if (accessToken) {
            getUser();
        } else {
            login(true).catch(e => console.log(e));
        }
    }, [accessToken]);

    const user = data?.user ?? null;

    if (!accessToken || !user) {
        return (
            <Loading/>
        );
    }

    if (user && !role && user.roles.length) {

        if (window.setAdsRoles) {
            window.setAdsRoles(user.roles);
        }

        let storageRole =  window.localStorage.getItem('userSetRole') ? window.localStorage.getItem('userSetRole') : user.roles[0];

        if (user.roles.indexOf(storageRole) < 0) {
            storageRole = user.roles[0];
        }

        setRole(storageRole);
    }

    return (
        <UserContext.Provider value={{user, logout, role, setRole}}>
            {props.children}
        </UserContext.Provider>
    )
}

const popup = (target) => // Not used
    {
        const windowArea = {
            width: Math.floor(window.outerWidth * 0.8),
            height: Math.floor(window.outerHeight * 0.5),
        };

        if (windowArea.width < 1000) {
            windowArea.width = 1000;
        }
        if (windowArea.height < 630) {
            windowArea.height = 630;
        }
        windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2));
        windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8));

        const sep = (target.indexOf('?') !== -1) ? '&' : '?';
        const url = `${target}${sep}`;
        const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${windowArea.width},height=${windowArea.height},
    left=${windowArea.left},top=${windowArea.top}`;

        const authWindow = window.open(url, 'producthuntPopup', windowOpts);
        // Create IE + others compatible event handler
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        const eventer = window[eventMethod];
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        // Listen to message from child window
        return new Promise((resolve, reject) => {
            eventer(messageEvent, (e) => {
                if (e.source !== authWindow) {
                    return;
                }
                if (e.origin !== (window.location.protocol + '//' + window.location.host)) {
                    authWindow.close();
                    reject('Not allowed');
                }

                if (e.data.auth) {
                    resolve(e.data.auth);
                    authWindow.close();
                } else {
                    authWindow.close();
                    reject('Unauthorised');
                }
            }, false);
        });
    }
;
