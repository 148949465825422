import * as React from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Icon(
    {
        size,
        icon,
        className,
        ...attr
    }
) {
    return (
        <i className={classnames(
            className,
            'icon',
                `icon-${icon}`,
            {
                [`icon-${size}`]: size,
            }
        )} {...attr}/>
    );
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    className: PropTypes.string,
};

Icon.defaultProps = {
    size: 'md',
}

export default Icon;
