import React, {useContext, useEffect, useState} from "react";
import {Settings} from 'luxon';

const LocaleContext = React.createContext({
    locale: null,
    setLocale: () => {},
});

export function useLocale() {
    const {locale, setLocale} = useContext(LocaleContext);
    return [locale, setLocale];
}

export function LocaleProvider(props) {
    const [locale, setLocale] = useState('cs');

    useEffect(() => {
        Settings.defaultLocale = locale;
    }, [locale]);

    return (
        <LocaleContext.Provider value={{locale, setLocale}}>
            {props.children}
        </LocaleContext.Provider>
    )
}

export const formats = {
    number: {
        CZK: {
            style: 'currency',
            currency: 'CZK',
        }
    }
}
