import * as React from "react";
import PropTypes from 'prop-types';
import Icon from "./Icon";

function Loading(props) {
    const {inline, hasTextAfter, hasTextBefore} = props;
    const size = inline ? 'xl' : 'xxxl';

    let classes = 'loading-spinner-container' + (inline ? '-inline' : '');
    classes += hasTextAfter ? ' mr-2' : '';
    classes += hasTextBefore ? ' ml-2' : '';

    return (
        <div className={classes}>
            <div className="spinner">
                <Icon icon="spinner" size={'xl'}/>
            </div>
        </div>
    );
}

Loading.propTypes = {
    inline: PropTypes.bool,
    hasTextAfter: PropTypes.bool,
    hasTextBefore: PropTypes.bool,
}

export default Loading;
