import {generatePath} from "react-router-dom5";
import * as Config from "user/helpers/config";
import {array} from "prop-types";

export const routes = {
    homepage: "/",
    dashboard: "/dashboard",
    dashboardStats: '/dashboard/stats',
    dashboardNews: '/dashboard/news',
    dashboardAchievements: '/dashboard/achievements',
    assignments: '/assignments',
    assignment: '/assignments/:assignmentId(\\d+)',
    assignmentElaborate: '/assignments/:assignmentId(\\d+)/elaborate',
    assignmentLinkFromTeacher: '/assignments/:assignmentId(\\d+)/link',
    assignmentNew: '/assignments/new',
    classrooms: '/classrooms',
    classroom: '/classrooms/:classroomId(\\d+)',
    classroomNew: '/classrooms/new',
    classroomAdd: '/classrooms/add',
    messages: '/messages',
    settings: '/settings',
    news: '/news',
    newsDetail: '/news/:newsId(\\d+)',
    history: '/history',
    recommendation: '/recommendation',
    children: '/children',
    child: '/children/:childId(\\d+)',
    childStats: '/children/:childId(\\d+)/stats',
    orders: '/order',
    order: '/order/:orderId(\\d+)',
    licences: '/licence',
    contentPacks: '/content-pack',
    contentPack: '/content-pack/:packId(\\d+)',
};

export function generateUrl(route, params, query) {
    let path = generatePath(route, params);

    if (typeof query !== "undefined") {
        const arrayProperties = Object.keys(query).filter(key => Array.isArray(query[key]));
        const nullProperties = Object.keys(query).filter(key => query[key] === null);
        const queryCopy = Object.assign({}, query);
        for (const property of arrayProperties) {
            delete queryCopy[property];
        }
        for (const property of nullProperties) {
            delete queryCopy[property];
        }
        const searchParams = new URLSearchParams(queryCopy)
        for (const property of arrayProperties) {
            query[property].forEach(value => {
                searchParams.append(property, value);
            });
        }
        path += '?' + searchParams.toString();
    }

    return path;
}

export function absoluteUrl(path) {
    return Config.get('basename') + path;
}

export function getInitialStateFromQuery(defaultValues) {
    return Array.from(defaultValues.entries()).reduce((carry, [key, value]) => {
        if (Array.isArray(carry[key])) {
            carry[key].push(value);
        } else if (typeof carry[key] !== "undefined") {
            carry[key] = [carry[key], value];
        } else {
            carry[key] = value;
        }
        return carry;
    }, {});
}
