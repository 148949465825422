import {useMutation} from "@apollo/client";
import {useEffect, useState} from "react";
import {generatePath, useHistory} from 'react-router-dom5';

function getValidationErrors(validationError, model) {
    const errors = Object.entries(validationError.extensions.validation);
    const fields = Object.keys(model);

    return errors.reduce((carry, [name, fieldErrors]) => {
        const fieldName = name.split('.').slice(-1)[0];

        if (fields.includes(fieldName)) {
            carry[fieldName] = fieldErrors.map(e => e.message);
        }
        return carry;
    }, {});
}

export function useMutationWithState(mutation, mutationOptions, opt = {}) {
    const defaultOptions = {
        interval: 5000,
        redirect: null,
        callback: null,
        formRef: null,
    };

    const options = Object.assign({}, defaultOptions, opt);

    const [state, setState] = useState('');
    const [timer, setTimer] = useState(null);
    const history = useHistory();

    const reset = () => setState('');
    const redirect = (data) => {
        const target = generatePath(options.redirect.path, data);
        history.push(target);
    }

    const [mutate, result] = useMutation(mutation, Object.assign({}, mutationOptions, {
        onError: (e) => {
            setState('failure');
            setTimer(setTimeout(reset, options.interval));
            let error;
            if (options.formRef?.current && (error = e.graphQLErrors.find(error => error.extensions.category === "arguments_validation_error"))) {
                options.formRef.current.updateInputsWithError(
                    getValidationErrors(error, options.formRef.current.getModel()), true
                );
            }
        },
        onCompleted: (data) => {
            setState('success');

            if (options.redirect) {
                redirect(options.redirect.dataToParams(data));
            } else if (options.callback) {
                options.callback();
            } else {
                setTimer(setTimeout(reset, options.interval));
            }
        },
    }));

    useEffect(() => { // To clear timer if user leaves the page before redirect / back transition happens
        return () => {
            clearTimeout(timer);
        }
    }, [timer])

    return [state, (options) => {
        setState('loading');
        return mutate(options);
    }, result];
}

const listOffsetLimitPagination = keyArgs => {
    return {
        keyArgs,
        merge(existing, incoming, {args}) {
            const merged = existing?.nodes ? existing.nodes.slice(0) : [];
            const incomingNodes = incoming?.nodes ? incoming.nodes : [];
            if (args) {
                // Assume an offset of 0 if args.offset omitted.
                const {offset = 0, limit = existing?.totalCount} = args;
                for (let i = 0; i < incomingNodes.length; ++i) {
                    merged[offset + i] = incomingNodes[i];
                }

                for (let i = incomingNodes.length; i < limit; ++i) {
                    if (typeof merged[offset + i] !== "undefined") {
                        delete merged[offset + i];
                    }
                }
            } else {
                // It's unusual (probably a mistake) for a paginated field not
                // to receive any arguments, so you might prefer to throw an
                // exception here, instead of recovering by appending incoming
                // onto the existing array.
                merged.push.apply(merged, incomingNodes);
            }

            return {
                totalCount: Number.isInteger(incoming?.totalCount) ? incoming?.totalCount : existing?.totalCount,
                nodes: merged,
            };
        },
        read(existing, {args: {offset = 0, limit}}) {
            // A read function should always return undefined if existing is
            // undefined. Returning undefined signals that the field is
            // missing from the cache, which instructs Apollo Client to
            // fetch its value from your GraphQL server.
            if (!existing) {
                return undefined;
            }

            if (!limit) {
                return {
                    totalCount: existing?.totalCount,
                    nodes: existing?.nodes.slice(offset),
                };
            }

            const nodes = existing?.nodes.slice(offset, offset + limit);
            if (nodes.length < limit && (!existing?.totalCount || nodes.length < (existing.totalCount - offset))) {
                return undefined;
            }

            return {
                totalCount: existing?.totalCount,
                nodes,
            };
        },
    };
}

const sortKeyArgs = ['sortOrder', 'sortField'];

export function getTypePolicies() {
    return {
        Query: {
            fields: {
                activity: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'Activity',
                            id: args.id,
                        });
                    }
                },
                activityList: listOffsetLimitPagination(['filter', 'ids', ...sortKeyArgs]),
                assignment: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'Assignment',
                            id: args.id,
                        });
                    }
                },
                // assignmentList: listOffsetLimitPagination(['filter', 'ids', ...sortKeyArgs]),
                classLabelList: listOffsetLimitPagination(['filter', ...sortKeyArgs]),
                classroom: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'Classroom',
                            id: args.id,
                        });
                    }
                },
                classroomList: listOffsetLimitPagination(['filter', 'ids', ...sortKeyArgs]),
                classroomMember: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'ClassroomMember',
                            id: args.id,
                        });
                    }
                },
                classroomMemberList: listOffsetLimitPagination(['filter', ...sortKeyArgs]),
                memberAssignment: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'MemberAssignment',
                            id: args.id,
                        });
                    }
                },
                memberAssignmentList: listOffsetLimitPagination(['filter', ...sortKeyArgs]),
                message: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'Message',
                            id: args.id,
                        });
                    }
                },
                messageList: listOffsetLimitPagination(['filter', ...sortKeyArgs]),
                subjectLabelList: listOffsetLimitPagination(['filter', ...sortKeyArgs]),
                newsList: listOffsetLimitPagination(['filter', ...sortKeyArgs]),
            }
        }
    }
}
